<!-- 企业简介 -->
<template>
  <div>
    <!-- <div class="video">
      <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/banner_1.png" alt="" />
    </div> -->
    <div class="content_top">
      <div class="content_top-box">
        <h3>{{ text17 }}</h3>
        <div>
          <p>{{ text18 }}</p>
          <br />
          <p>{{ text19 }}</p>
          <br />
          <p>{{ text20 }}</p>
          <br />
          <p>{{ text21 }}</p>
          <br />
          <p>{{ text22 }}</p>
          <br />
          <p>{{ text28 }}</p>
        </div>
      </div>
    </div>
    <div class="content_bottom">
      <div class="content_bottom_left">
        <div class="left_top">
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/jianjie1.png" alt="" />
          <p>{{ text23 }}</p>
        </div>
        <div class="left_bottom">
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/jianjie3.png" alt="" />
          <p>{{ text25 }}</p>
        </div>
      </div>
      <!-- <div class="content_bottom_right">
        <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/jianjie3.png" alt="" />
        <p>{{ text25 }}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text17: "关于明济生物",
          text18: "明济生物制药(北京)有限公司是一家创新生物制药公司,致力于为患者提供高质量的创新生物药,研发产品覆盖肿瘤、免疫疾病等领域。",
          text19:
            "明济生物最初于2014年创立于美国旧金山，创始人及共同创始人拥有超过20年于跨国制药公司研发创新抗体的经验，领导开发了一系列针对恶性肿瘤，免疫疾病及病毒感染的抗体类药物，曾发表多篇论文于“Cell”, “Nature”等期刊。2016年北京研发中心成立，2018年引入A轮融资后，明济生物开始扎根中国，并逐步发展壮大。",
          text20:
            "公司已经建立拥有了一系列具有自主知识产权的创新抗体药物以及独特的STEP技术平台，应用抗体智能设计及动态进化筛选为核心的创新抗体工程技术于抗体筛选及人源化、肿瘤免疫靶点及抗体验证平台、稳定细胞株构建平台等。公司利用这些技术平台并通过我们对肿瘤免疫领域及抗体工程的深刻理解，设计开发创新单抗、双抗、多抗及ADC类药物。",
          text21: "此外，明济生物已在北京大兴完成了中试生产基地的建设，初步建立完整的抗体药物开发产业链，涵盖上游抗体药物筛选验证平台，以及下游小试中试、药物分析质控等抗体药物生产平台。",
          text22: "明济生物第一个自主研发产品M108于2021年进入临床试验阶段并在上海组建临床医学团队。",
          text28: "我们希望汇集各路英才开发出能够真正满足临床需求及解决病人痛苦的创新生物药，并成长为全球领先的生物制药公司。",
          text23: "研发中心及中试生产基地位于大兴",
          text24: "研发副中心位于昌平",
          text25: "上海分公司位于静安",
          text27: "媒体报道",
          isShow: false,
        };
        break;
      case "en":
        return {
          text17: "About FutureGen",
          text18: "FutureGen is an innovative biopharmaceutical company committed to providing high-quality innovative biologics for patients with cancer, immune diseases and other diseases. ",
          text19:
            "FutureGen was founded in San Francisco, USA in 2014. The founder and co-founders have more than 20 years of experience in the development of innovative antibodies in multinational pharmaceutical companies. They led the development of a series of antibody drugs for malignant tumors, immune diseases and viral infections, and published several papers in Cell, Nature and other journals. FutureGen began to set up R&D center in Beijing, China in 2016 and successful completion of  Series A financing in 2018. ",
          text20:
            "FutureGen has developed a series of innovative antibody drugs with independent intellectual property rights and established a unique STEP technology platform, and the innovative antibody engineering technology with antibody intelligent design and dynamic evolutionary screening applied in antibody screening and humanization, tumor immune target and antibody verification platform, and stable cell line construction platform. The company has designed and developed innovative monoclonal antibodies, bispecific antibodies, multispecific antibodies and ADC drugs based on these technology platforms and deep understandings in tumor immunity and antibody engineering. ",
          text21:
            "In addition, FutureGen has completed the construction of a pilot production base in Daxing, Beijing, and initially established a complete industrial chain of antibody drug development, covering the upstream antibody drug screening and validation platform, as well as the downstream antibody drug production platform, such as pilot test, drug analysis and quality control. ",
          text22: "FutureGen′s first independently developed product, M108 entered clinical trial in 2021, for which the medical team was set up in Shanghai. ",
          text28: "We hope to grow into a globally leading biopharmaceutical with talents to develop innovative biologics that can truly meet clinical needs and relieve patient suffering. ",
          text23: "The R&D Center and Pilot Production Base are located in Daxing, Beijing, China.",
          text24: "The sub-R&D Center is located in Changping, Beijing, China. ",
          text25: "The branch is located in Jing 'an, Shanghai, China. ",
          text27: "Media Report",
          isShow: false,
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  methods: {
    changeLang(flag) {
      // console.log(flag);
      // this.isLanguage = flag;
      // this.$store.commit("setisLanguage", flag);
      language = flag;
      localStorage.setItem("language", language);
      location.reload();
    },
    toggle: function () {
      console.log(111);
      // document.getElementById("list_hide").toggle()
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}

.div {
  position: sticky;
  top: 0;
  width: 100vw;
  height: 130px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  .box {
    .list {
      color: #fff;
      li {
        height: 25px;
        text-align: center;
        line-height: 30px;
        a {
          color: #fff;
          text-decoration: none;
        }
        div {
          a {
            color: black;
          }
        }
        .hide {
          p {
            color: #333;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .div {
    width: 100vw;
    height: 56px;
    .box {
      position: relative;
      padding-top: 0px;
      height: 56px;
      background-color: rgba(0, 0, 0, 0.2);
      .logo {
        display: none;
      }
      .list {
        position: absolute;
        top: 105%;
        left: 0;
        width: 100vw;
        height: 60vh;
        box-sizing: border-box;
        background-color: #203864;
        flex-direction: column;
        padding: 30px;
        text-align: left;
        li {
          a {
            color: #fff;
            line-height: 50px;
          }
          div {
            top: 8px;
            left: 33%;
            width: 120px;
          }
        }
      }
      .taggle {
        display: none;
      }
      .hide {
        width: 100vw;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hide_left {
          position: relative;
          width: 26px;
          height: 40px;
          margin-left: 10px;
          background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/src/icon.png") no-repeat;
          background-position: -104px 0;
          .list_hide {
            position: absolute;
            width: 60vw;
            top: 112%;
            left: -20px;
            width: 100vw;
            height: 100vh;
            list-style: none;
            box-sizing: border-box;
            background-color: #203864;
            flex-direction: column;
            padding: 16px 30px;
            li {
              font-size: 20px;
              display: flex;
              flex-direction: column;
              a {
                color: #fff;
                line-height: 30px;
              }
              .nav_two {
                margin-left: 20px;
                font-size: 16px;
              }
              div {
                top: 90px;
                left: 33%;
                width: 120px;
                background-color: rgba(0, 0, 0, 0.493);
              }
              .hide {
                display: none;
                z-index: 99;
              }
            }
            :hover .hide {
              display: block;
            }
          }
        }

        .hide_logo {
          img {
            width: 100px;
            margin-left: 20px;
            margin-top: 4px;
            -webkit-tap-highlight-color: transparent;
          }
        }
        .hide_taggle {
          position: relative;
          width: 70px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          .hide_taggle_box {
            display: none;
            position: absolute;
            top: 120%;
            left: -25%;
            width: 75px;
            color: #333;
            padding: 7px;
            border: 1px solid rgba(51, 51, 51, 0.288);
            background-color: #fff;
          }
        }
        .hide_taggle:hover {
          .hide_taggle_box {
            display: block;
          }
        }
      }
    }
    .swiper {
      width: 100%;
      height: 250px;
    }
  }
}

.head {
  background-color: rgba(0, 0, 0, 0.3);
}
.video {
  width: 100vw;
  height: 1080px;
  margin-top: -130px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/banner.png");
  img {
    margin: 27% 48%;
  }
}
.content_top {
  width: 100vw;
  background-color: #203864;
  box-sizing: border-box;
  .content_top-box {
    width: 100;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    color: #fff;
    opacity: 0.8;
    padding: 130px 0px;
    justify-content: space-between;
    box-sizing: border-box;
    h3 {
      position: relative;
      width: 22vw;
      font-size: 50px;
    }
    h3::after {
      position: absolute;
      top: 22%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      opacity: 0.7;
      background-color: #ccc;
    }
    div {
      width: 829px;
      p {
        line-height: 24px;
        font-size: 18px;
      }
    }
  }
}
.content_bottom {
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .content_bottom_left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left_top {
      border-radius: 20px;
      background-color: #203864;
      overflow: hidden;
      width: 48%;
      img {
        width: 100%;
      }
      p {
        width: 100%;
      }
    }
    .left_bottom {
      border-radius: 20px;
      background-color: #203864;
      overflow: hidden;
      width: 48%;
      img {
        width: 100%;
      }
      p {
        width: 100%;
      }
    }
  }
  .content_bottom_right {
    width: 525px;
    border-radius: 20px;
    background-color: #203864;
  }
  p {
    font-size: 22px;
    color: #fff;
    text-align: center;
    padding: 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .video {
    height: 290px;
    background-size: 100% 100%;
    margin-top: -56px;
    img {
      width: 60px;
      height: 60px;
      margin: 31% 42%;
    }
  }
  .content_top {
    width: 100vw;
    .content_top-box {
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
      h3 {
        width: 70vw;
        font-size: 37px;
        margin-bottom: 8vh;
      }
      h3::after {
        top: 115%;
      }
      div {
        width: 90vw;
      }
    }
  }
  .content_bottom {
    margin: 50px auto;
    flex-direction: column;
    .content_bottom_left {
      width: 87vw;
      flex-direction: column;
      p {
        font-size: 20px;
        line-height: 30px;
      }
      .left_top {
        padding-bottom: 10px;
        margin-bottom: 14px;
        img {
          width: 87vw;
        }
      }
      .left_bottom {
        margin-top: 30px;
        padding-bottom: 10px;
        img {
          width: 87vw;
        }
      }
    }
    .content_bottom_right {
      width: 87vw;
      margin-top: 40px;
      img {
        width: 87vw;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
</style>
